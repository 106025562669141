<template>
<section class="service_m">
   <img class="banner" :src="$t('locale')=='zh'?banner:banner_en" alt="" />
   <div class="service_tabs">
      <mu-container>
         <mu-tabs
            :value.sync="active1"
            inverse
            full-width>
            <mu-tab>{{ $t("PC.Service.gflg0l") }}</mu-tab>
            <mu-tab>{{ $t("PC.Service.7nqh23") }}</mu-tab>
         </mu-tabs>
         <div class="tab_1 tab" v-if="active1 === 0">
            <div class="t1_box1">
               <p class="h1">{{ $t("PC.Service.f27zgm") }}</p>
               <p class="p2">{{ $t("PC.Service.eweb7a") }}</p>
               <p class="h1">{{ $t("PC.Service.0gdzei") }}</p>
               <div class="icon_pic-contain">
                  <div
                     class="icon_pic-box"
                     v-for="(val, index) in iconsymbol"
                     :key="index">
                     <svg class="iconsymbol" aria-hidden="true">
                        <use :xlink:href="val.icon"></use>
                     </svg>
                     <p>{{ val.text }}</p>
                  </div>
               </div>
            </div>
            <div class="t1_box2">
               <p class="h1">{{ $t("PC.Service.jdmfns") }}</p>
               <p class="p2">{{ $t("PC.Service.4nimxt") }}</p>
               <div class="b2_contain-box">
                  <img src="../../assets/zh/images-mobile//shiye_img1@2x.jpg" alt="" />
                  <div>
                     <p>{{ $t("PC.Service.d7yvpr") }}</p>
                     <p>{{ $t("PC.Service.v47kbf") }}</p>
                  </div>
               </div>
               <div class="b2_contain-box">
                  <img src="../../assets/zh/images-mobile//shiye_img2@2x.jpg" alt="" />
                  <div>
                     <p>{{ $t("PC.Service.9n5odf") }}</p>
                     <p>{{ $t("PC.Service.y6e7ep") }}</p>
                  </div>
               </div>
               <div class="b2_contain-box">
                  <img src="../../assets/zh/images-mobile//shiye_img3@2x.jpg" alt="" />
                  <div>
                     <p>{{ $t("PC.Service.pimt4e") }}</p>
                     <p>{{ $t("PC.Service.lcbhgj") }}</p>
                  </div>
               </div>
               <div class="b2_contain-box b2cb-4">
                  <img src="../../assets/zh/images-mobile//shiye_img4@2x.jpg" alt="" />
                  <div>
                     <p>{{ $t("PC.Service.5qjn0r") }}</p>
                     <p>
                        {{ $t("PC.Service.caba4v") }}
                     </p>
                  </div>
               </div>
               <p class="last_p" v-html="$t('PC.Service.hns1nc')"></p>
            </div>
            <div class="t1_box3">
               <p class="h1">{{ $t("PC.Service.1b8ynk") }}</p>
               <div class="box_contain">
                  <div class="t1b3_box">
                     <svg class="iconsymbol" aria-hidden="true">
                        <use xlink:href="#iconshiye_bangong"></use>
                     </svg>
                     <p>{{ $t("PC.Service.ieh5e6") }}</p>
                  </div>
                  <div class="t1b3_box">
                     <svg class="iconsymbol" aria-hidden="true">
                        <use xlink:href="#iconshiye_xiangmu"></use>
                     </svg>
                     <p>{{ $t("PC.Service.r7wdip") }}</p>
                  </div>
                  <div class="t1b3_box">
                     <svg class="iconsymbol" aria-hidden="true">
                        <use xlink:href="#iconshiye_zijin1"></use>
                     </svg>
                     <p v-html="$t('PC.Service.fxqbpg')"></p>
                  </div>
                  <div class="t1b3_box">
                     <svg class="iconsymbol" aria-hidden="true">
                        <use xlink:href="#iconshiye_caiwu"></use>
                     </svg>
                     <p>{{ $t("PC.Service.i6mygh") }}</p>
                  </div>
                  <div class="t1b3_box">
                     <svg class="iconsymbol" aria-hidden="true">
                        <use xlink:href="#iconshiye_hehuoren"></use>
                     </svg>
                     <p>{{ $t("PC.Service.smk038") }}</p>
                  </div>
                  <div class="t1b3_box">
                     <svg class="iconsymbol" aria-hidden="true">
                        <use xlink:href="#iconshiye_shichang"></use>
                     </svg>
                     <p>{{ $t("PC.Service.6vxpr9") }}</p>
                  </div>
                  <div class="t1b3_box last-box">
                     <svg class="iconsymbol" aria-hidden="true">
                        <use xlink:href="#iconshiye_shouru"></use>
                     </svg>
                     <p>
                        {{ $t("PC.Service.oykasb") }}
                     </p>
                  </div>
               </div>
            </div>
            <div class="t1_box4 swiper">
               <p class="h1">合伙人职责</p>
               <div class="swiper-container2">
                  <div class="swiper-wrapper">
                     <div class="swiper-slide">
                        <div class="swiper-box">
                           <img src="../../assets/zh/images-mobile//shiye_img6@2x.jpg" alt="" />
                           <article class="mask">
                              {{ $t("PC.Service.c28i94") }}
                           </article>
                        </div>
                     </div>
                     <div class="swiper-slide">
                        <div class="swiper-box">
                           <img src="../../assets/zh/images-mobile//shiye_img7@2x.jpg" alt="" />
                           <article class="mask">
                              {{ $t("PC.Service.53n5e8") }}
                           </article>
                        </div>
                     </div>
                     <div class="swiper-slide">
                        <div class="swiper-box">
                           <img src="../../assets/zh/images-mobile//shiye_img8@2x.jpg" alt="" />
                           <article class="mask">
                              {{ $t("PC.Service.58j3hr") }}
                           </article>
                        </div>
                     </div>
                     <div class="swiper-slide">
                        <div class="swiper-box">
                           <img src="../../assets/zh/images-mobile//shiye_img9@2x.jpg" alt="" />
                           <article class="mask">
                              {{ $t("PC.Service.eyki86") }}
                           </article>
                        </div>
                     </div>
                     <div class="swiper-slide">
                        <div class="swiper-box">
                           <img src="../../assets/zh/images-mobile//shiye_img10@2x.jpg" alt="" />
                           <article class="mask">
                              {{ $t("PC.Service.klq0pj") }}
                           </article>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="t1_box5">
               <p class="h1">{{ $t("PC.Service.zypmfd") }}</p>
               <article>
                  <div class="c5_box">
                     <svg class="iconsymbol" aria-hidden="true">
                        <use xlink:href="#iconshiye_chuangye"></use>
                     </svg>
                     <p>{{ $t("PC.Service.bj1etd") }}</p>
                     <p>{{ $t("PC.Service.p4ed3m") }}</p>
                  </div>
                  <div class="c5_box">
                     <svg class="iconsymbol" aria-hidden="true">
                        <use xlink:href="#iconshiye_xueli"></use>
                     </svg>
                     <p>{{ $t("PC.Service.l93wdq") }}</p>
                     <p>
                        {{ $t("PC.Service.11bzyr") }}
                     </p>
                  </div>
                  <div class="c5_box last-c5_box">
                     <svg class="iconsymbol" aria-hidden="true">
                        <use xlink:href="#iconshiye_suzhi"></use>
                     </svg>
                     <p>{{ $t("PC.Service.57rt9p") }}</p>
                     <p>{{ $t("PC.Service.p5od6g") }}</p>
                  </div>
               </article>
            </div>
            <div class="map_contain">
               <p class="h1">{{ $t("PC.Service.txdblr") }}</p>
               <img src="../../assets/zh/images-mobile//aboutus_img2@2x.png" alt="" />
               <div>
                  <p>{{ $t("PC.Service.bqpct5") }}</p>
                  <p>{{ $t("PC.Service.xz6nnu") }}</p>
                  <p>{{ $t("PC.Service.0ldt5w") }}</p>
               </div>
            </div>
         </div>
         <div class="tab_2 tab" v-if="active1 === 1">
            <ul>
               <li>
                  <p>{{ $t("PC.Service.4mpt1c") }}</p>
                  <span>{{ $t("PC.Service.fb5rhr") }}</span>
               </li>
               <li>
                  <p>{{ $t("PC.Service.kixe3o") }}</p>
                  <span>{{ $t("PC.Service.ijf1pe") }}</span>
               </li>
               <li>
                  <p>{{ $t("PC.Service.httj06") }}</p>
                  <span>{{ $t("PC.Service.ia6gkv") }}</span>
               </li>
               <li>
                  <p>{{ $t("PC.Service.mye5uo") }}</p>
                  <span>{{ $t("PC.Service.u4446i") }}</span>
               </li>
               <li>
                  <p>{{ $t("PC.Service.fk9uwq") }}</p>
                  <span>{{ $t("PC.Service.ioq2jl") }}</span>
               </li>
            </ul>
         </div>
      </mu-container>
   </div>
   <FooterB></FooterB>
</section>
</template>

<script>
import FooterB from "./components/FooterB";
export default {
   components: {
      FooterB,
   },
   data() {
      return {
         iconsymbol: [{
               icon: "#iconshiye_jishu",
               text: this.$t("PC.Service.98nu5s"),
            },
            {
               icon: "#iconshiye_ziyuan",
               text: this.$t("PC.Service.8btb2o"),
            },
            {
               icon: "#iconshiye_zijin",
               text: this.$t("PC.Service.05tv89"),
            },
            {
               icon: "#iconshiye_qvdao",
               text: this.$t("PC.Service.913u8y"),
            },
            {
               icon: "#iconshiye_shangji",
               text: this.$t("PC.Service.ay404s"),
            },
         ],
         dutyList: [{}, {}],
         activeName: "first",
         active1: 0,
         banner: require('../../assets/zh/images-mobile//shiye_banner@2x.jpg'),
         banner_en: require('../../assets/en/images-mobile//shiye_banner@2x.png'),
      };
   },
   mounted() {
      this.swiperDemo();
   },
   methods: {
      swiperDemo() {
         var swiper = new Swiper(".swiper-container2", {
            autoplay: false,
            allowTouchMove: true,
            preloadImages: false,
            centeredSlides: false,
            spaceBetween: 16,
            slidesOffsetBefore: 16,
            loop: true,
            slidesPerView: "auto",
         });
         window.onresize = function () {
            swiper.update();
         };
      },
   },
};
</script>

<style lang="scss" scoped>
.service_m {
   margin-top: 48px;
   word-break: break-word !important;

   .banner {
      width: 100%;
      height: 150px;
   }

   .service_tabs {
      div.container {
         padding: 0;

         .mu-tabs {
            z-index: 3;
         }

         /deep/.mu-tabs-inverse {
            background-color: #f5f6f9;
            color: #333;
         }

         /deep/.tab {
            background: #f5f6f9;
         }

         /deep/.mu-secondary-text-color {
            color: #003299;
         }

         /deep/.mu-tab-link-highlight {
            background-color: transparent !important;
         }

         /deep/.mu-tab-active.is-inverse {
            color: #003299;
         }

         /deep/.mu-tab-wrapper {
            font-weight: 500 !important;
            padding: 0;
         }

         .tab_1 {
            background-color: #fff;

            .h1 {
               text-align: center;
               font-size: 16px;
               color: #333;
               line-height: 22px;
               padding-bottom: 12px;
            }

            .t1_box1 {
               box-sizing: border-box;
               padding: 20px 20px 24px 20px;

               .p2 {
                  padding-bottom: 24px;
                  font-size: 12px;
                  color: #333;
                  line-height: 24px;
                  text-align: center;
               }

               .icon_pic-contain {
                  padding-top: 4px;
                  display: flex;
                  justify-content: space-between;

                  .icon_pic-box {
                     svg {
                        width: 52px;
                        height: 52px;
                     }

                     p {
                        text-align: center;
                        max-width: 55px;
                     }
                  }
               }
            }

            .t1_box2 {
               background-color: #f5f6f9;
               box-sizing: border-box;
               padding: 16px 20px 18px;

               .p2 {
                  font-size: 12px;
                  color: #333;
                  line-height: 24px;
                  text-align: center;
                  padding-bottom: 12px;
               }

               .b2_contain-box {
                  padding-bottom: 8px;

                  img {
                     width: 335px;
                     height: 168px;
                  }

                  div {
                     width: 335px;
                     height: auto;
                     background-color: #fff;
                     padding: 12px;
                     box-sizing: border-box;

                     p:nth-of-type(1) {
                        font-size: 14px;
                        color: #333;
                        line-height: 20px;
                        padding-bottom: 4px;
                     }

                     p:nth-of-type(2) {
                        font-size: 12px;
                        font-family:   PingFangSC-Regular, PingFang SC;
                        color: #666;
                        line-height: 17px;
                     }
                  }
               }

               .b2_contain-box.b2cb-4 {
                  div {
                     background-image: url("../../assets/zh/images-mobile//shiye_img5@2x.jpg");
                     background-size: contain;
                     background-repeat: no-repeat;
                     width: 335px;
                     height: 123px;
                     padding: 16px;

                     p:nth-of-type(2) {
                        font-size: 12px;
                        font-family:   PingFangSC-Regular, PingFang SC;
                        color: #666;
                        line-height: 21px;
                     }
                  }
               }

               p.last_p {
                  text-align: center;
                  font-size: 12px;
                  font-weight: 500;
                  color: #333;
                  line-height: 24px;
               }
            }

            .t1_box3 {
               box-sizing: border-box;
               padding: 20px;
               padding-bottom: 11px;

               .box_contain {
                  display: flex;
                  justify-content: space-between;
                  flex-wrap: wrap;

                  .t1b3_box {
                     width: 163px;
                     height: auto;
                     box-sizing: border-box;
                     padding: 12px;
                     background-color: #f9fafb;
                     margin-bottom: 9px;

                     svg {
                        width: 24px;
                        height: 24px;
                        padding-bottom: 8px;
                     }

                     p {
                        color: #666666;
                        padding-top: 12px;
                        border-top: 2px solid #0044d0;
                     }
                  }

                  .last-box {
                     width: 335px;
                     height: 106px;
                  }
               }
            }

            .t1_box4 {
               -moz-user-select: none;
               -webkit-user-select: none;
               user-select: none;
               position: relative;

               .swiper-container2 {
                  overflow: visible;
                  height: 130px;

                  .swiper-wrapper {
                     width: 100%;
                     height: 130px;

                     .swiper-slide {
                        width: 172px;
                        height: 130px;
                        overflow: hidden;
                        position: relative;
                        transition: all 100ms linear;

                        div.swiper-box {
                           width: 100%;
                           height: 100%;
                           position: relative;

                           img {
                              width: 100%;
                              height: 100%;
                           }

                           .mask {
                              width: 172px;
                              height: 43px;
                              background-color: rgba($color: #000, $alpha: 0.58);
                              position: absolute;
                              bottom: 0;
                              text-align: center;

                              font-size: 14px;
                              color: #ffffff;
                              line-height: 43px;
                           }
                        }
                     }
                  }
               }
            }

            .t1_box5 {
               box-sizing: border-box;
               padding: 20px;

               article {
                  .c5_box {
                     width: 276px;
                     margin: 0 auto;
                     text-align: center;
                     margin-bottom: 12px;

                     svg {
                        width: 98px;
                        height: 98px;
                     }

                     p:nth-of-type(1) {
                        font-size: 14px;
                        color: #333333;
                        line-height: 28px;
                        margin: 7px 0 2px;
                     }

                     p:nth-of-type(2) {
                        font-family:   PingFangSC-Regular, PingFang SC;
                        font-size: 12px;
                        font-weight: 400;
                        color: #666666;
                        line-height: 17px;
                     }
                  }

                  .last-c5_box {
                     margin-bottom: 0;
                  }
               }
            }

            .map_contain {
               width: 335px;
               margin: 0 auto 32px;

               img {
                  width: 335px;
                  height: 190px;
               }

               div {
                  width: 335px;
                  height: auto;
                  background: #282a35;
                  box-sizing: border-box;
                  padding: 48px;

                  p {
                     color: #fff;
                     height: auto;
                     font-size: 12px;
                     line-height: 17px;
                     margin-bottom: 16px;
                  }
               }
            }
         }

         .tab_2 {
            box-sizing: border-box;
            background-color: #fff;
            padding: 20px;

            ul {
               li {
                  background-image: url("../../assets/zh/images/fuwu_beijing.jpg");
                  background-size: cover;
                  background-repeat: no-repeat;
                  margin-bottom: 16px;
                  padding: 27px 16px;

                  &>p {
                     text-align: center;
                     font-size: 14px;
                     color: #333;
                     line-height: 20px;
                     font-weight: 600;
                     padding-bottom: 20px;
                  }

                  span {
                     width: 100%;
                     font-size: 12px;
                     font-family:   PingFangSC-Regular, PingFang SC;
                     color: #666;
                     line-height: 21px;
                  }
               }
            }
         }
      }
   }
}
</style>
